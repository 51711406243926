<template>
    <el-main>
        <router-view v-slot="{ Component }">
            <transition mode="out-in" name="el-zoom-in-center" appear>
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
    </el-main>
</template>

<style lang="scss" scoped>
.el-main {
    height: calc(100vh - 99px);
    padding: 10px;
}
</style>
